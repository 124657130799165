<template>
    <div class="patient-table-container px-0 mx-0">
        <PatientDetailPreview :patient="selectedPatient" />
        <IodReportPopup v-if="modalShow" :modalShow.sync="modalShow" :lensOrderId="lensOrderId" />
        <b-table
            id="table-patient-list"
            :items="patientList"
            :fields="fields"
            no-local-sorting
            no-border-collapse
            fixed
            responsive
            sticky-header="55vh"
            :sort-by="camelCase(queryParams.sortBy)"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #head()="data">
                <div class="content">
                    {{ data.label }}
                </div>
            </template>

            <template #head(updated)>
                <div class="content light-border pointer">
                    <div class="d-flex flex-column column-explanation">
                        <span>{{ t('activity') }}</span>
                        <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                    </div>
                </div>
            </template>

            <template #cell(updated)="{value, item}">
                <span class="text-caption text-gray-dark">
                    <span class="white-space-pre">{{ value | date }}</span>
                    <template v-if="!item.active">
                        {{ t('inactive') }}
                    </template>
                </span>
            </template>

            <template #cell(actions)="data">
                <div class="d-flex">
                    <b-icon
                        class="pointer mr-2"
                        icon="pencil-fill"
                        variant="gray-dark"
                        @click="goToPatientDetailsEditMode(data.item.patientId)"
                        v-if="
                            checkPermissions({
                                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
                            })
                        "
                    ></b-icon>
                    <b-img
                        class="mr-2"
                        :src="previewIcon"
                        v-b-modal.patient-detail-preview
                        @click="selectedPatient = data.item"
                    ></b-img>
                    <b-img :src="asterisk" v-if="data.item.priority" width="18" height="18"></b-img>
                </div>
            </template>

            <!-- ALL column -->

            <template #head(expanded)>
                <div class="content pointer" @click="expandAll">
                    {{ t('all') }}
                </div>
            </template>

            <template #cell(expanded)="{item, value}">
                <div class="d-flex">
                    <b-icon
                        v-if="expandable(item)"
                        class="ml-1 pointer"
                        @click="expand(item)"
                        :icon="value ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                </div>
            </template>

            <!-- End ALL column -->

            <template #head(name)>
                <div class="content pointer">
                    <div class="d-flex flex-column column-explanation">
                        <span>{{ t('patientId') }}</span>
                        <div
                            v-if="!activeCustomer.hidePatientNames"
                            class="mt-1 mr-2 column-explanation-text w-100"
                        >
                            {{ t('patientNameLastFirst') }}
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(name)="data">
                <custom-router-link
                    :to="{
                        name: 'PatientDetails',
                        params: {patientId: data.item.patientId},
                    }"
                    class="patient-link text-primary d-block mb-1 text-caption"
                >
                    {{ data.item.patientRef }}
                </custom-router-link>
                <p
                    v-if="!activeCustomer.hidePatientNames"
                    class="text-caption light text-gray-dark mb-0"
                >
                    {{ namesFormatter(data.item.lastName, data.item.firstName) }}
                </p>
            </template>

            <template #head(dateOfBirth)>
                <div class="content">
                    <div class="d-flex flex-column column-explanation">
                        <span>{{ t('dob') }}</span>
                        <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                    </div>
                </div>
            </template>

            <template #cell(dateOfBirth)="data">
                <span class="text-caption text-gray-dark">
                    {{ data.value | date({isUTC: false}) }}
                </span>
            </template>

            <template #head(doctorFullName)>
                <div class="content pointer">
                    <div class="d-flex flex-column column-explanation">
                        <span>{{ t('surgeon') }}</span>
                        <div class="mt-1 column-explanation-text">
                            {{ t('LastFirst') }}
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(doctorFullName)="{item}">
                <div class="text-caption heavy d-flex align-items-start">
                    {{
                        surgeonIdNameFormat(
                            item.ocosDoctorId,
                            `${item.doctorLastName}, ${item.doctorFirstName}`,
                            item.doctorActive
                        )
                    }}
                </div>
            </template>

            <template #head(odCalculations)>
                <div class="header-box left d-flex align-items-center pl-2 pt-1">
                    <img src="@/assets/eye_icon_white.svg" />
                    <img src="@/assets/eye_icon_gray_orig.svg" />
                </div>
                <div class="content">
                    {{ t('calculations') }}
                </div>
            </template>

            <template #cell(odCalculations)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.odLensDetails,
                        'selectedPrescription',
                        false
                    )"
                    :key="i"
                >
                    <p class="text-caption light text-gray-dark mb-1 pl-0 ml-0">
                        {{
                            decimalSeparatorFormatter(
                                lensDetail['selectedModel'],
                                currentUser.decimalSeparator
                            )
                        }}
                    </p>
                    <div class="d-flex">
                        <p
                            class="text-caption heavy text-black mb-0"
                            :title="lensDetail['selectedPrescriptionString']"
                        >
                            {{
                                decimalSeparatorFormatter(
                                    lensDetail['selectedPrescriptionString'],
                                    currentUser.decimalSeparator
                                )
                            }}
                        </p>
                        <b-button
                            id="printReport"
                            @click="
                                printReport(
                                    getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OD)
                                )
                            "
                            class="pointer btn-print"
                            v-if="
                                hasPatientCalculationPermission &&
                                lensDetail['selectedPrescriptionString']
                            "
                        >
                            <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                        </b-button>
                    </div>
                </div>
                <p
                    v-if="
                        hasPatientCalculationPermission &&
                        !lensRange(data.item.odLensDetails, 'selectedPrescription', false).length &&
                        calculationStatus(data.item.preOpDataItems, EyeSides.OD)
                    "
                    class="text-nowrap text-caption heavy text-black mb-1"
                >
                    {{ calculationStatus(data.item.preOpDataItems, EyeSides.OD) }}
                    <b-button
                        id="printReport"
                        @click="
                            printReport(getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OD))
                        "
                        class="pointer btn-print"
                    >
                        <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                    </b-button>
                </p>
            </template>

            <template #head(odLens)>
                <div class="header-box middle d-flex align-items-center">
                    <span class="text-subheader heavy text-white">OD</span>
                </div>
                <div class="content">
                    <b-row no-gutters>
                        <b-col offset="3" cols="6">
                            {{ t('odLens') }}
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #cell(odLens)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.odLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="np lens-nudge min-h-sub-row"
                >
                    <b-row no-gutters>
                        <b-col cols="3">
                            <b-button
                                v-if="showIodButton(lensDetail)"
                                @click="iodBtnClicked(lensDetail)"
                                variant="outline-primary"
                                class="btn-iod"
                            >
                                {{ isSidButton(lensDetail) }}
                            </b-button>
                        </b-col>
                        <b-col cols="9">
                            <div>
                                <p class="text-caption light text-gray-dark mb-1">
                                    {{ lensDetail['reservedModel'] }}
                                </p>
                                <p
                                    class="text-caption heavy text-black mb-0"
                                    :title="lensDetail['reservedPrescriptionString']"
                                >
                                    {{
                                        decimalSeparatorFormatter(
                                            lensDetail['reservedPrescriptionString'],
                                            currentUser.decimalSeparator
                                        )
                                    }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #head(odOrder)>
                <div class="header-box right"></div>
                <div class="content">
                    {{ t('order') }}
                </div>
            </template>

            <template #cell(odOrder)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.odLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="d-flex align-items-start np lens-nudge min-h-sub-row"
                >
                    <div>
                        <p class="text-caption heavy text-black mb-1">
                            {{
                                getReservationOrderStatus(lensDetail.status, {
                                    isConsignment: isConsignment(lensDetail),
                                })
                            }}
                            -
                            <custom-router-link
                                :to="{
                                    name:
                                        lensDetail.isPreOrder && isDistributorSurgeonMode
                                            ? 'PreOrderDetails'
                                            : pageName[lensDetail.status],
                                    params: {orderId: lensDetail.orderId},
                                }"
                            >
                                {{ lensDetail.orderNumber }}</custom-router-link
                            >
                        </p>
                        <p
                            v-if="lensDetail.shippingProvider"
                            class="text-caption heavy text-black mb-1"
                        >
                            {{ lensDetail.shippingProvider }}: {{ lensDetail.shippingNumber }}
                        </p>
                        <p
                            v-if="lensDetail.serial"
                            class="text-caption light text-gray-dark mb-0 text-nowrap"
                            :title="lensDetail.serial"
                        >
                            {{
                                t(isConsignment(lensDetail) ? 'invLookup_Consignment' : 'serialNum')
                            }}
                            {{ lensDetail.serial }}
                        </p>
                    </div>
                </div>
            </template>

            <template #head(osCalculations)>
                <div class="header-box left"></div>
                <div class="content">
                    {{ t('calculations') }}
                </div>
            </template>

            <template #cell(osCalculations)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.osLensDetails,
                        'selectedPrescription',
                        false
                    )"
                    :key="i"
                >
                    <p class="text-caption light text-gray-dark mb-1">
                        {{
                            decimalSeparatorFormatter(
                                lensDetail['selectedModel'],
                                currentUser.decimalSeparator
                            )
                        }}
                    </p>
                    <div class="d-flex">
                        <p
                            class="text-caption heavy text-black mb-0"
                            :title="lensDetail['selectedPrescriptionString']"
                        >
                            {{
                                decimalSeparatorFormatter(
                                    lensDetail['selectedPrescriptionString'],
                                    currentUser.decimalSeparator
                                )
                            }}
                        </p>
                        <b-button
                            id="printReport"
                            @click="
                                printReport(
                                    getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OS)
                                )
                            "
                            class="pointer btn-print"
                            v-if="
                                hasPatientCalculationPermission &&
                                lensDetail['selectedPrescriptionString']
                            "
                        >
                            <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                        </b-button>
                    </div>
                </div>
                <p
                    v-if="
                        hasPatientCalculationPermission &&
                        !lensRange(data.item.osLensDetails, 'selectedPrescription', false).length &&
                        calculationStatus(data.item.preOpDataItems, EyeSides.OS)
                    "
                    class="text-nowrap text-caption heavy text-black mb-1"
                >
                    {{ calculationStatus(data.item.preOpDataItems, EyeSides.OS) }}
                    <b-button
                        id="printReport"
                        @click="
                            printReport(getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OS))
                        "
                        class="pointer btn-print"
                    >
                        <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                    </b-button>
                </p>
            </template>

            <template #head(osLens)>
                <div class="header-box middle d-flex align-items-center">
                    <span class="text-subheader heavy text-white">OS</span>
                </div>
                <div class="content">
                    <b-row no-gutters>
                        <b-col offset="3" cols="6">
                            {{ t('osLens') }}
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #cell(osLens)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.osLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="np lens-nudge min-h-sub-row"
                >
                    <b-row no-gutters>
                        <b-col cols="3">
                            <b-button
                                v-if="showIodButton(lensDetail)"
                                @click="iodBtnClicked(lensDetail)"
                                variant="outline-primary"
                                class="btn-iod"
                            >
                                {{ isSidButton(lensDetail) }}
                            </b-button>
                        </b-col>
                        <b-col cols="9">
                            <div>
                                <p class="text-caption light text-gray-dark mb-1">
                                    {{ lensDetail['reservedModel'] }}
                                </p>
                                <p
                                    class="text-caption heavy text-black mb-0"
                                    :title="lensDetail['reservedPrescriptionString']"
                                >
                                    {{
                                        decimalSeparatorFormatter(
                                            lensDetail['reservedPrescriptionString'],
                                            currentUser.decimalSeparator
                                        )
                                    }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #head(osOrder)>
                <div
                    class="header-box right d-flex align-items-center justify-content-end pr-2 pt-1"
                >
                    <img src="@/assets/eye_icon_gray_orig.svg" />
                    <img src="@/assets/eye_icon_white.svg" />
                </div>
                <div class="content">
                    {{ t('order') }}
                </div>
            </template>

            <template #cell(osOrder)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.osLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="d-flex align-items-start np lens-nudge min-h-sub-row"
                >
                    <div>
                        <p class="text-caption heavy text-black mb-1">
                            {{
                                getReservationOrderStatus(lensDetail.status, {
                                    isConsignment: isConsignment(lensDetail),
                                })
                            }}
                            -
                            <custom-router-link
                                :to="{
                                    name:
                                        lensDetail.isPreOrder && isDistributorSurgeonMode
                                            ? 'PreOrderDetails'
                                            : pageName[lensDetail.status],
                                    params: {orderId: lensDetail.orderId},
                                }"
                            >
                                {{ lensDetail.orderNumber }}</custom-router-link
                            >
                        </p>
                        <p
                            v-if="lensDetail.shippingProvider"
                            class="text-caption heavy text-black mb-1"
                        >
                            {{ lensDetail.shippingProvider }}: {{ lensDetail.shippingNumber }}
                        </p>
                        <p
                            v-if="lensDetail.serial"
                            class="text-caption light text-gray-dark mb-0"
                            :title="lensDetail.serial"
                        >
                            {{
                                t(isConsignment(lensDetail) ? 'invLookup_Consignment' : 'serialNum')
                            }}
                            {{ lensDetail.serial }}
                        </p>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {get, isEmpty} from 'lodash';
import {Status, EyeSides} from '@/store/modules/preopdata';
import preview_icon from '@/assets/eye_icon_patient_list.svg';
import od_icon from '@/assets/od_icon.svg';
import os_icon from '@/assets/os_icon.svg';
import double_arrow_icon from '@/assets/double-arrows.svg';
import asterisk from '@/assets/asterisk.svg';
import {SortCategories} from '@/constants/patientlist';
import {OrderSubStatus, PageName, IodSidButtonOrderStatuses} from '@/constants/order';
import {
    namesFormatter,
    surgeonIdNameFormat,
    decimalSeparatorFormatter,
    getReservationOrderStatus,
} from '@/utilities/formatters';
import PatientDetailPreview from './PatientDetailPreview.vue';
import IodReportPopup from '@/views/iodreport/IodReportPopup.vue';
import {InventorySource} from '@/constants/inventory';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import camelCase from 'lodash/camelCase';

export default {
    name: 'PatientTable',
    components: {
        IodReportPopup,
        PatientDetailPreview,
    },
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            OrderSubStatus,
            EyeSides,
            lensOrderId: null,
            modalShow: false,
            isAllExpanded: false,

            /* Icons */
            previewIcon: preview_icon,
            odIcon: od_icon,
            osIcon: os_icon,
            doubleArrow: double_arrow_icon,
            asterisk,

            selectedPatient: {},

            /* Pseudo-'enum' that stores the string that will be sent to the backend for each sortable category. */
            sortCategories: SortCategories,

            // Current sort orders for each category
            activitySortOrder: 'desc',
            patientNameSortOrder: 'desc',
            surgeonSortOrder: 'desc',

            fields: [
                {
                    key: 'updated',
                    label: 'Activity',
                    tdClass: 'light-border',
                    class: 'col-updated',
                    sortable: true,
                },
                {key: 'actions', label: '', class: 'col-actions'},
                {
                    key: 'name',
                    label: 'Patient Id',
                    stickyColumn: true,
                    class: 'col-name',
                    sortable: true,
                },
                {key: 'dateOfBirth', label: 'DOB', class: 'col-dob'},
                {key: 'doctorFullName', label: 'Surgeon', class: 'col-doctor', sortable: true},
                {
                    key: 'odCalculations',
                    label: 'Calculations',
                    class: 'col-calc heavy-border',
                },
                {key: 'odLens', label: 'Lens OD', class: 'col-lens'},
                {key: 'odOrder', label: 'Order', class: 'col-order'},
                {
                    key: 'osCalculations',
                    label: 'Calculations',
                    class: 'col-calc heavy-border',
                },
                {key: 'osLens', label: 'Lens OS', class: 'col-lens'},
                {key: 'osOrder', label: 'Order', class: 'col-order'},
                {key: 'expanded', label: 'All', class: 'col-expanded heavy-border'},
            ],
        };
    },

    computed: {
        ...mapGetters('user', ['activeCustomer', 'currentUser', 'activeCustomerOcosNumberOrId']),
        ...mapGetters('patientList', ['patientList']),
        ...mapGetters('permissions', ['permissions']),

        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },

        hasPatientCalculationPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_CALCULATIONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        pageName() {
            return PageName;
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [this.PERMISSIONS.DISTRIBUTOR_MODE]: [this.PERMISSIONS_VALUES.SURGEON],
            });
        },
    },

    methods: {
        camelCase,
        namesFormatter,
        surgeonIdNameFormat,
        decimalSeparatorFormatter,
        getReservationOrderStatus,
        ...mapMutations({
            setShowPatientDetailsPreview: 'patientList/setShowPatientDetailsPreview',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        filterOrderedLens(lens) {
            // distributor surgeons do not see the actual order, so they need to see their ordered pre-orders
            if (this.hasOrderingPermission || this.isDistributorSurgeonMode) {
                return true;
            }
            return lens.status != OrderSubStatus.ORDERED;
        },
        calculationStatus(preOpDataItems, side) {
            const preOpDataItem = preOpDataItems.find((x) => x.opEye == side);
            const labels = {
                [Status.SAVED]: this.t('preOpDataSaved'),
                [Status.CALCULATED]: this.t('targetLensPending'),
            };
            return labels[get(preOpDataItem, 'status')];
        },
        isConsignment(lens) {
            return lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
        lensRange(dataItem, key, expanded) {
            /* TODO/NOTE: Once the endpoint is updated, this function will actually determine whether to show 1 lens or all lenses, based on the toggle. */
            dataItem = dataItem.filter(
                (lens) =>
                    !Object.values(get(lens, key, {})).every(isEmpty) &&
                    this.filterOrderedLens(lens)
            );
            let maxLength = dataItem.length >= 4 ? 4 : dataItem.length;
            if (maxLength == 0) {
                return [];
            }
            let realLength = expanded ? maxLength : 1;

            let subArray = dataItem.slice(0, realLength);
            return subArray;
        },

        /* Navigate to the main Patient Details page for the patient, starting in edit mode. */
        goToPatientDetailsEditMode(id) {
            this.$router.push({
                name: 'PatientDetails',
                params: {patientId: id, startInEditMode: true},
            });
        },

        expand(item) {
            const expanded = !item.expanded;
            this.$set(item, 'expanded', expanded);
            this.$set(item, 'icon', expanded ? 'chevron-up' : 'chevron-down');
        },

        expandable(patient) {
            return (
                patient.odLensDetails.filter(
                    (l) => !Object.values(l.reservedPrescription).every(isEmpty)
                ).length > 1 ||
                patient.osLensDetails.filter(
                    (l) => !Object.values(l.reservedPrescription).every(isEmpty)
                ).length > 1
            );
        },
        expandAll() {
            this.isAllExpanded = !this.isAllExpanded;
            this.patientList.forEach((data) => {
                this.$set(data, 'expanded', this.isAllExpanded);
                this.$set(data, 'icon', this.isAllExpanded ? 'chevron-up' : 'chevron-down');
            });
        },
        /**
         * Determines whether to show the IOD button or not
         *
         * The IOD button is shown when the lens' order status is
         * in the set of desired statuses, and the lens has a serial number.
         *
         * @param {Object} lensDetail - the lens details
         */
        showIodButton(lensDetail) {
            return (
                (this.isConsignment(lensDetail) ||
                    IodSidButtonOrderStatuses.includes(lensDetail.status)) &&
                !lensDetail.serial?.includes('MTO')
            );
        },

        /**
         * Determines if the lens is a Spheric or Toric for the IOD/SID button
         *
         * @param {Object} lensDetails - the lens details
         * returns button text for Sperhic or Toric
         */
        isSidButton(lensDetail) {
            return lensDetail.selectedPrescription.cylinder === undefined ? 'SID' : 'IOD';
        },

        /**
         * Handles when the IOD button is clicked
         *
         * @param {Object} lensDetails - the lens details
         */
        iodBtnClicked(lensDetails) {
            this.lensOrderId = lensDetails.lensOrderId.toString();
            this.modalShow = true;
        },
        /**
         * Gets the PreOp dataset ID
         *
         * @param {Array} preOpDataItems - collection of PreOp data items
         * @param {String} eye - the eye to get the dataset for
         */
        getPreOpDataSetId(preOpDataItems, eye) {
            const preOpDataItem = preOpDataItems.find((x) => x.opEye === eye);
            return preOpDataItem?.preOpDataSetId;
        },
        /**
         * Opens a new window with a printable PreOp report
         *
         * @param {String} preOpDataSetId - the dataset ID to get the PreOp
         *   report for
         */
        printReport(preOpDataSetId) {
            const routeData = this.$router.resolve({
                name: 'PreOpReport',
                params: {preOpDataSetId},
                query: {
                    ['account-id']: this.activeCustomerOcosNumberOrId,
                },
            });

            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables';
.np {
    padding-top: 4px;
    padding-bottom: 4px;
}
.table-patient-list th {
    color: rgb(133, 132, 132);
    font-weight: 100;
}

.two-line-min {
    min-height: 35px;
}

.lens-nudge {
    margin-top: -3px;
}
.min-h-sub-row {
    min-height: 44px;
}
button.btn-iod {
    font-size: 0.7rem;
    padding: 0.2rem 0.15rem;
    border-radius: 0.25rem;
    margin-top: -0.3rem;
}

button.btn-print {
    display: contents;
    color: $gray-dark;
}
</style>
